import axios from "axios";

// Model Controller
const ModelController = (function () {
  const Model = { state: {} };

  Model.loadAssets = async function () {
    const endpoint = "/api/v1/assets";
    const response = await axios(endpoint);
    const sorted = response?.data?.data.sort((a, b) => {
      if (a.asset_name > b.asset_name) return 1;
      return -1;
    });
    this.state.assets = sorted.length ? sorted : [];
  };

  return Model;
})();

export default ModelController;
