// App Controller
import UIController from "./UI";
import ModelController from "./Model";

const AppController = (function (UICtrl, ModelCrl) {
  const App = {};

  App.toggleSelect = function (asset_id) {
    const selected = ModelCrl.state.assets.find(
      (asset) => asset.asset_id === asset_id
    );
    ModelCrl.state.selected = selected;
    UICtrl.changeFormSetting(ModelCrl.state.selected);
  };

  App.getCurrentSelected = function () {
    return ModelCrl.state.selected;
  };

  App.handleSubmit = function (data) {
    return ModelCrl.state.selected;
  };

  App.conroller = async function () {
    UICtrl.init();
    await ModelCrl.loadAssets();
    const assets = ModelCrl.state;
    UICtrl.livePriceWrapper && UIController.renderRates(assets);
    UICtrl.exchangeFormWrapper && UICtrl.renderForm(assets);
    UICtrl.renderNavDropdown(assets);
    UICtrl.exchangeFormWrapper && UICtrl.toggleSelect(this.toggleSelect);
    UICtrl.exchangeFormWrapper &&
      UICtrl.handleInputChange(this.getCurrentSelected);
    UICtrl.exchangeFormWrapper &&
      UICtrl.addHandlerSubmit(this.getCurrentSelected, this.handleSubmit);
  };

  App.init = function () {
    UICtrl.addHandlerLoad(this.conroller.bind(this));
  };

  return App;
})(UIController, ModelController);

export default AppController;
