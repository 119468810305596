import simpleParallax from "simple-parallax-js";

// UI Controller
const UIController = (function () {
  const UI = {};
  UI.window = window;
  UI.document = document;
  UI.body = document.body;
  UI.header = document.querySelector(".header");
  UI.headerOffsets = document.querySelectorAll(".avoid-nav");
  UI.thumbnails = document.querySelectorAll(".thumbnail");
  UI.animationTextWrappers = document.querySelectorAll(
    ".animated-text-wrapper"
  );
  UI.animationWrappers = document.querySelectorAll(".animated");
  UI.navs = document.querySelectorAll(".nav");
  UI.internalLinks = document.querySelectorAll(".internal-link");
  UI.scrollRotates = document.querySelectorAll(".scroll-rotate");
  UI.scrollTransforms = document.querySelectorAll(".scroll-transform");
  UI.header = document.querySelector(".header");
  UI.accordions = Array.from(document.querySelectorAll(".accordion"));
  UI.navToggler = document.querySelector(".nav-toggler");
  UI.mobileNav = document.querySelector(".mobile-nav-wrapper");
  UI.mobileNavInner = document.querySelector(".mobile-nav-inner");
  UI.customSelect = document.querySelector(".custom-select");
  UI.selectBtn = document.querySelector(".select-button");
  UI.selectedValue = document.querySelector(".selected-value");
  UI.selectedImage = document.querySelector(".selected-img");
  UI.dropdownToggleBtns = document.querySelectorAll(
    ".header-dropdown-link-toggle"
  );
  UI.optionWrapper = document.querySelector(".select-dropdown");
  UI.livePriceWrapper = document.querySelectorAll(".live-price-list-wrapper");
  UI.optionWrapperHeader = document.querySelectorAll(
    ".header-dropdown-link-wrapper"
  );
  UI.exchangeFormWrapper = document.querySelector(".exchange-form-wrapper");
  UI.tradedCurrency = document.querySelector(".traded-currency-text");
  UI.tradedCurrencyImg = document.querySelector(".traded-currency-img");
  UI.tradedRate = document.querySelector(".traded-rate");
  UI.tradedRange = document.querySelector(".traded-range");
  UI.inputAsset = document.querySelector(".form-input-asset");
  UI.inputNGN = document.querySelector(".form-input-ngn");

  UI.changeFormSetting = function (asset) {
    const {
      min_traded_value: min,
      max_traded_value: max,
      traded_currency: currency,
      rate,
      asset_name,
    } = asset;

    this.tradedCurrency.textContent = currency;
    this.tradedCurrencyImg.setAttribute(
      "src",
      `/img/currency/${currency.toLowerCase()}.png`
    );

    const encodedText = encodeURIComponent(
      `Hello, What is the rate for ${asset_name}`
    );
    let href = `https://wa.me/+2349044946941?text=${encodedText}`;
    let link = ` <a href="${href}">CONTACT</a>`;

    this.tradedRate.innerHTML = rate ? `<span>${rate} NGN/${currency}` : link;
    const tradedRangeText = `${min ? min : ""}${max ? " - " + max : ""}`;
    // this.tradedRange.textContent = tradedRangeText
    this.inputAsset.setAttribute("placeholder", tradedRangeText);
    this.inputAsset.focus();
  };

  UI.handleInputChange = function (getCurrentSelected) {
    this.inputAsset.addEventListener("keydown", (e) => this.removeErrors());
    this.inputNGN.addEventListener("keydown", (e) => this.removeErrors());

    this.inputAsset.addEventListener("keyup", (e) => {
      e.preventDefault();

      const asset = getCurrentSelected();
      const result =
        +e.target.value === 0 ? "" : (+e.target.value * asset.rate).toFixed(2);
      this.inputNGN.value = isNaN(result) ? 0 : result;
    });

    this.inputNGN.addEventListener("keyup", (e) => {
      e.preventDefault();
      const asset = getCurrentSelected();
      const result =
        +e.target.value === 0 ? "" : (+e.target.value / asset.rate).toFixed(2);
      this.inputAsset.value = isNaN(result) ? 0 : result;
    });
  };

  UI.removeErrors = function () {
    this.inputAsset.classList.remove("error");
    this.exchangeFormWrapper
      .querySelectorAll(".form-error-msg")
      .forEach((err) => err.remove());
  };

  UI.addHandlerSubmit = function (getCurrentSelected, handler) {
    const exchangeForm =
      this.exchangeFormWrapper.querySelector(".exchange-form");
    if (!exchangeForm) return;
    exchangeForm.addEventListener("submit", (e) => {
      e.preventDefault();

      const asset = getCurrentSelected();

      const {
        min_traded_value: min,
        max_traded_value: max,
        traded_currency: currency,
        asset_name,
        rate,
      } = asset;

      if (min && +this.inputAsset.value < min) {
        this.removeErrors();
        this.inputAsset.classList.add("error");
        const errMsg = `<p class="text-danger form-error-msg text-sm" style="padding:.8rem 2rem 0 2.2rem">Min amount is: ${min} ${currency} </p>`;
        const inputContainer = this.inputAsset.closest(".form-input-wrapper");
        inputContainer.insertAdjacentHTML("beforebegin", errMsg);
        return;
      }

      if (max && +this.inputAsset.value > max) {
        this.removeErrors();
        this.inputAsset.classList.add("error");
        const errMsg = `<p class="text-danger form-error-msg text-sm" style="padding:.8rem 2rem 0 2.2rem">Max amount is: ${max} ${currency} </p>`;
        const inputContainer = this.inputAsset.closest(".form-input-wrapper");
        inputContainer.insertAdjacentHTML("beforebegin", errMsg);
        return;
      }

      const ngn = +this.inputNGN.value;
      const assetValue = +this.inputAsset.value;
      const message = `Hello, I want to trade ${assetValue} ${currency} worth of ${asset_name} for ${ngn} NGN`;
      const whatsappLink = `https://wa.me/+2349044946941?text=${encodeURI(
        message
      )}`;
      this.window.location.href = whatsappLink;
    });
  };

  UI.addHandlerLoad = function (handler) {
    this.window.addEventListener("load", handler);
  };

  UI.addImageParallax = function () {
    this.thumbnails.forEach((thumbnail) => {
      const image = thumbnail.querySelector("img");
      if (!image) return;
      new simpleParallax(image, {
        scale: 1.2,
        transition: "cubic-bezier(0,0,0,1)",
        delay: 1,
        // orientation: "left",
      });
    });
  };

  UI.addTextAnimation = function () {
    this.animationTextWrappers.forEach((wrapper) => {
      const enumTextType = ["word", "text"];
      const { textType, animationDuration } = wrapper.dataset;
      const text = wrapper.textContent.trim();

      if (!text || !textType || !enumTextType.includes(textType)) return;

      const breaker = textType === "text" ? "" : " ";
      let textSplit = text.split(breaker);

      textSplit = textSplit
        .filter((t) => t)
        .map((t) => {
          if (t.startsWith("\n")) return t.split("\n")[1];
          if (t.endsWith("\n")) return t.split("\n")[0];
          return t;
        });

      textSplit = textSplit.map((item, i) => {
        const delay = `${0.05 * i}s`;
        const duration = `${animationDuration || "0.8"}s`;

        const seperator = `<span class="inline-block animated-text">
        <span class="inline-block" style="animation-delay:${delay}; animation-duration:${duration}; ">${item}</span>
        </span>`;

        return seperator;
      });

      wrapper.innerHTML = textSplit.join(breaker);
    });
  };

  UI.showPage = function () {
    const container = this.body.querySelector("main");
    container.classList.remove("d-none");
  };

  UI.handleAnimation = function () {
    this.animationWrappers.forEach((wrapper) => {
      const observer = new IntersectionObserver(
        ([firstEntry], observer) => {
          const { target, isIntersecting } = firstEntry;
          target.classList.add("hidden");

          if (isIntersecting) {
            const { animation } = target.dataset;
            if (!animation) return;
            const { animationDelay, animationDuration } = target.dataset;
            if (isFinite(Number(animationDelay))) {
              setTimeout(() => {
                target.classList.remove("hidden");
              }, Number(animationDelay) * 1000);
            } else target.classList.remove("hidden");

            if (target.classList.contains("animated-text-wrapper")) {
              const animatedTexts = [...target.children];
              animatedTexts.forEach((text) => {
                text.querySelector("span").classList.add(animation);
              });
            } else {
              target.style.animationDelay = `${animationDelay || 0}s`;
              target.style.animationDuration = `${animationDuration || 1}s`;
              target.classList.add(animation);
            }

            observer.unobserve(target);
          }
        },
        {
          root: null,
          threshold: 0.5,
          rootMargin: "50px",
        }
      );

      observer.observe(wrapper);
    });
  };

  UI.linkTo = function (link) {
    if (!link) return;
    let { target } = link.dataset;
    target = this.document.querySelector(`#${target}`);
    if (!target) return;
    target.scrollIntoView({ behavior: "smooth" });
  };

  UI.handleLink = function () {
    this.internalLinks.forEach((link) => {
      link.addEventListener("click", (e) => {
        e.preventDefault();
        this.linkTo(link);
      });
    });
  };

  UI.hideHeader = function () {
    var prevScrollpos = this.window.scrollY; //window.pageYOffset;

    this.window.addEventListener("scroll", () => {
      if (this.navToggler.classList.contains("active")) return;

      var currentScrollPos = this.window.scrollY; //window.pageYOffset;

      if (prevScrollpos > currentScrollPos) {
        // this.header.style.top = "0";
        this.header.classList.remove("active");
      } else {
        // this.header.style.top = "-" + getComputedStyle(this.header).height;
        this.header.classList.add("active");
      }
      prevScrollpos = currentScrollPos;
    });
  };

  UI.rotateOnScroll = function () {
    this.window.addEventListener("scroll", () => {
      var theta = (document.documentElement.scrollTop / 50) % Math.PI;
      this.scrollRotates.forEach((item) => {
        item.style.transform = "rotate(" + theta + "rad)";
      });
    });
  };

  UI.translateOnScroll = function () {
    this.window.addEventListener("optimizedScroll", () => {
      this.scrollTransforms.forEach((item) => {
        const { transformDir } = item.dataset;
        const transformDirEnum = ["left", "right"];
        if (!transformDir || !transformDirEnum.includes(transformDir)) return;

        item.style.transform = `translate(${
          transformDir === "left" ? "-" : ""
        }${window.scrollY}px)`;
      });
    });
  };

  UI.trotted = function () {
    (() => {
      var throttle = function (type, name, obj) {
        var obj = obj || window;
        var running = false;
        var func = function () {
          if (running) {
            return;
          }
          running = true;
          requestAnimationFrame(function () {
            obj.dispatchEvent(new CustomEvent(name));
            running = false;
          });
        };
        obj.addEventListener(type, func);
      };
      throttle("scroll", "optimizedScroll");
    })();
  };

  UI.avoidNav = function (margin = 0) {
    let headerHeight = getComputedStyle(this.header).height;
    headerHeight = headerHeight.split("px")[0];
    this.headerOffsets.forEach((offset) => {
      offset.style.paddingTop = +headerHeight + margin + "px";
      // offset.style.paddingBottom = (+headerHeight + margin) / 2 + "px";
    });
  };

  UI.handleAccordion = function () {
    const toggle = (accordion) => {
      let panel = accordion.nextElementSibling;
      if (!panel) return;
      const accordionWrapper = accordion.parentElement;
      accordionWrapper.classList.toggle("active");
      panel.classList.toggle("active");
      panel.style.height = `${Number(panel.scrollHeight) + 60}px`;

      panel.style.maxHeight
        ? (panel.style.maxHeight = null)
        : (panel.style.maxHeight = `${Number(panel.scrollHeight + 60)}px`);
    };

    // if (this.accordions[0]) toggle(this.accordions[0]);

    this.accordions.forEach((accordion) => {
      if (!accordion) return;

      accordion.addEventListener("click", (e) => {
        e.preventDefault();
        toggle(accordion);
      });
    });
  };

  UI.handleToggler = function () {
    if (!this.navToggler) return;

    const toggleNav = () => {
      this.navToggler.classList.toggle("active");
      this.mobileNav.classList.toggle("active");

      setTimeout(
        () => this.mobileNavInner.classList.toggle("active"),
        1000 * 0.5
      );
    };

    this.navToggler.addEventListener("click", (e) => {
      toggleNav();

      this.mobileNav.addEventListener("click", (e) => {
        const link = e.target.closest(".internal-link");
        if (!link) return;
        this.navToggler.classList.remove("active");
        this.mobileNav.classList.remove("active");
        this.mobileNavInner.classList.remove("active");
      });
    });
  };

  UI.handleDropdownToggle = function () {
    if (!this.dropdownToggleBtns.length) return;
    this.dropdownToggleBtns.forEach((btn) => {
      const handleToggle = () => {
        const linkContainer = btn.querySelector(
          ".header-dropdown-link-container"
        );
        if (!linkContainer) return;
        btn.classList.toggle("active");
        linkContainer.classList.toggle("active");
      };

      btn.addEventListener("click", handleToggle);
    });
  };

  UI.toggleSelect = function (handler) {
    this.optionsList = document.querySelectorAll(".option-list");

    // add click event to select button
    this.selectBtn.addEventListener("click", () => {
      // add/remove active class on the container element
      this.customSelect.classList.toggle("active");
      // update the aria-expanded attribute based on the current state
      this.selectBtn.setAttribute(
        "aria-expanded",
        this.selectBtn.getAttribute("aria-expanded") === "true"
          ? "false"
          : "true"
      );
    });

    const self = this;

    const handleSelection = (option) => {
      self.optionsList.forEach((op) => op.classList.remove("active"));
      const selected = option.querySelector(".selected-text");
      const { selectedImage, selectedId, min, rate } = selected.dataset;
      handler(selectedId);

      self.inputAsset.value = "";
      self.inputNGN.value = "";

      self.selectedImage.setAttribute("src", `/img/assets/${selectedImage}`);
      self.selectedValue.textContent = selected.textContent;
      self.customSelect.classList.remove("active");
      option.classList.add("active");
    };

    const handleOption = (option) => {
      option.classList.remove("active");
      option.addEventListener("click", function (e) {
        // Click Events
        if (e.type === "click" && e.clientX !== 0 && e.clientY !== 0) {
          handleSelection(option);
        }
      });
    };

    const url = new URL(window.location.href);
    const current_asset_id = url.searchParams.get("asset_id");
    const currentOption = Array.from(this.optionsList).find((opt) => {
      const { selectedId } = opt.dataset;
      return selectedId === current_asset_id;
    });

    if (currentOption) handleSelection(currentOption);
    else if (this.optionsList[0]) handleSelection(this.optionsList[0]);
    this.optionsList.forEach(handleOption);
  };

  UI.renderRates = function (assets) {
    this.livePriceWrapper.forEach((wrapper) => {
      const loader = wrapper.querySelector(".loader-wrapper");
      loader && loader.remove();

      const optionsListTemplate = assets.assets.map((asset) => {
        const encodedText = encodeURIComponent(
          `Hello, What is the rate for ${asset.asset_name}`
        );
        let href = `https://wa.me/+2349044946941?text=${encodedText}`;
        let link = ` <a href="${href}">CONTACT</a>`;
        if (asset.rate)
          href = `/exchange/${asset.asset_name}-to-naira?asset_id=${asset.asset_id}`;

        return `
       <li class="live-price-list">
          <div class="live-price-card flex align-center ">
            <div class="live-price-asset flex align-center ">
              <span
                class="flex align-center mr-1 "
                style="height: 2.4rem; width: 2.4rem; border-radius: 50%"
              >
                <img
                  src="/img/assets/${asset.image}"
                  alt="Sell ${asset.asset_name} for Naira on Swiftexchange.ng"
                  style="height: 2.4rem; width: 2.4rem; border-radius: 50%; display: block; object-fit:cover"
                />
              </span>

              <div class="flex-1 flex column text-sm text-dark uppercase">
                <span class="font-bold">${asset.asset_name} </span>
              </div>
            </div>
            <div class="live-price-curreny">
              <div class="pl-1 flex column text-sm text-dark">
                <span class="font-bold">${asset.traded_currency}</span>
              </div>
            </div>
              <div class="live-price-rate">
              <div class="pl-1 flex column text-sm text-dark">
                <span class="font-bold"> ${
                  asset.rate ? asset.rate + " NGN" : link
                } 
                   </span>
              </div>
            </div>
            <div class="live-price-curreny">
              <div class="pl-1 flex column text-sm text-dark">
                <span class="font-bold">${
                  asset.min_traded_value ? asset.min_traded_value : "--"
                }</span>
              </div>
            </div>
            <div class="live-price-curreny">
              <div class="pl-1 flex column text-sm text-dark">
                <span class="font-bold">${
                  asset.max_traded_value ? asset.max_traded_value : "--"
                }</span>
              </div>
            </div>
          
            <div class="live-price-sell">
              <div class="cta-wrapper">
                <a
                  href="${href}"
                  class="cta cta-dark cta-sm cta-rounded"
                >
                  <span>Sell</span>
                </a>
              </div>
            </div>
          </div>
        </li>

      `;
      });

      wrapper.insertAdjacentHTML("beforeend", optionsListTemplate.join(""));
    });
  };

  UI.renderNavDropdown = function (assets) {
    this.optionWrapperHeader.forEach((wrapper) => {
      const loader = wrapper.querySelector(".loader-wrapper");
      loader && loader.remove();

      const optionsListTemplate = assets.assets.map((asset) => {
        const encodedText = encodeURIComponent(
          `Hello, I want to trade ${asset.asset_name}`
        );
        let href = `https://wa.me/+2349044946941?text=${encodedText}`;
        if (asset.rate)
          href = encodeURI(
            `/exchange/${asset.asset_name}-to-naira?asset_id=${asset.asset_id}`
          );

        return `
        <li class="header-dropdown-link-list">
            <a href="${href}"
            class="header-dropdown-link"
            >
            <span class="block header-dropdown-link-img">
            <img
              src="/img/assets/${asset.image}"
            alt="Sell ${asset.asset_name} on Swiftexchange.ng"
             />
            </span>
            <span
            class="block header-dropdown-link-text text-sm font-bold text-dark pl-1 uppercase"
            >${asset.asset_name} </span
             >
          </a>
       </li>
      `;
      });

      wrapper.insertAdjacentHTML("afterbegin", optionsListTemplate.join(""));
    });
  };

  UI.renderForm = function (assets) {
    const exchangeForm =
      this.exchangeFormWrapper.querySelector(".exchange-form");
    const loader = this.exchangeFormWrapper.querySelector(".loader-wrapper");
    exchangeForm.classList.toggle("d-none");
    loader && loader.remove();

    const optionsListTemplate = assets.assets
      // .filter((asset) => asset.rate)
      .map((asset) => {
        return `  <li role="option" class="option-list" data-selected-id="${
          asset.asset_id
        }">
            <input type="radio" id="${asset.asset_id}" name="${
          asset.asset_id
        }" />
            <label for="${asset.asset_id}">
              <div class="flex align-center">
                <img
                  class="input-selected-img"
                  src="/img/assets/${asset.image}"
                  alt="Sell ${asset.asset_name} for Naira on Swiftexchange.ng"
                  style="
                    width: 3rem;
                    height: 3rem;
                    border-radius: 50%;
                    display: block;
                  "
                />
              </div>
              <span class="uppercase selected-text" data-selected-image="${
                asset.image
              }" data-rate="${asset.rate}" data-selected-id="${
          asset.asset_id
        }" data-min="${asset.min_traded_value}" data-max="${
          asset.max_traded_value
        }">
                ${asset.asset_name || asset.symbol}  
              </span>
            </label>
          </li>`;
      });

    this.optionWrapper.insertAdjacentHTML(
      "afterbegin",
      optionsListTemplate.join("")
    );
  };

  UI.init = function () {
    this.trotted();
    this.handleLink();
    this.showPage();
    this.avoidNav();
    // this.addImageParallax();
    this.addTextAnimation();
    this.handleAnimation();
    this.hideHeader();
    this.rotateOnScroll();
    this.translateOnScroll();
    this.handleAccordion();
    this.handleToggler();
    this.handleDropdownToggle();
    // this.toggleSelect();
  };

  return UI;
})();

export default UIController;
